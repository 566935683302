<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" >
      <img src="assets/images/logo-iot.png" alt="logo" class="logoIndex">
     <!-- <b>{{dataAlerte_a_affiche.length}}</b> -->
    </a>
  </div>
  <!-- <nb-select  style="margin-left: 50px;"     >
    <nb-option selected="true"   value="Compteur 1"> Compteur 1</nb-option>
  </nb-select> -->
</div>
<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
        <nb-search type="rotate-layout"></nb-search>  
    </nb-action> -->
      <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -- -->
    <nb-action class="control-item" (click)="goto();" icon="bell-outline"></nb-action>
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               nbContextMenuTag="my-context-menu"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div> 
  <div *ngIf="dataAlerte_a_affiche.length > 0" class="mybadge"></div>
